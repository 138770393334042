import { ExistingFile } from './document.types';

export enum AgeRange {
  UNDER_TWENYTHREE = 'under-23',
  TWENYTHREE_SIXTYNINE = '23_and_69',
  SEVENTY_OR_ABOVE = '70-or-above',
}

export type BasicUserData = {
  first_name?: string | null;
  last_name?: string | null;
  email: string;
  full_name: string;
};

export type PersonalInfo = BasicUserData & {
  phone_number?: string | null;
  age_range?: AgeRange | null;
  profile_photo?: ExistingFile;
  licensed_two_years_or_more?: boolean | null;
  vehicle_currently_insured?: boolean | null;
  terms_and_conditions_accepted?: boolean | null;
  contact_by_phone_number_accepted?: boolean;
};

export type PersonalInfoInput = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  licensed_two_years_or_more?: boolean | null;
  vehicle_currently_insured?: boolean | null;
  age_range?: AgeRange | null;
  terms_and_conditions_accepted: boolean;
  contact_by_phone_number_accepted: boolean;
};
