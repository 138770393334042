import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApplicantStatusEnum } from '../../__generated__/graphql';
import MainContentCard from '../../components/layout/main-layout/main-content-card';
import MultipleApplicationsAlert from '../../components/multiple-applications-alert/multiple-applications-alert';
import { validateIdentityVerificationBackgroundCheckFailed } from '../../components/multiple-applications-alert/util';
import Spinner from '../../components/spinner';
import { APPLICATION_STATUS } from '../../constants/routes.constants';
import { UseStepForm, useStepForm } from '../../hooks/use-step-form';
import { useTranslation } from '../../hooks/use-translation';
import { CurrentUserContext } from '../../providers/current-user.context';
import { OnboardingApplication } from '../../types/applicant.types';
import getOnboardingApplication from './application-steps.service';
import {
  getAllStepsOrReopenedSteps,
  getCurrentStep,
  getPageTitle,
} from './util';

type CheckForRejectionsProps = {
  application: OnboardingApplication;
  onNoRejections: () => void;
};

export type OutletContextProps = UseStepForm & {
  onboardingApplication: OnboardingApplication | null;
  setOnboardingApplication: (
    onboardingApplication: OnboardingApplication,
  ) => void;
  checkForRejections: (props: CheckForRejectionsProps) => boolean;
};

function ApplicationStepsPage(): JSX.Element {
  const { t } = useTranslation('personal-info');
  const location = useLocation();
  const navigate = useNavigate();
  const { applicantId } = useParams();

  const { existingApplications } = useContext(CurrentUserContext);

  // state
  const [onboardingApplication, setOnboardingApplication] =
    useState<OnboardingApplication | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const reopenedSteps = onboardingApplication?.applicant.reopened_steps;

  const {
    isNextEnable,
    isPrevEnable,
    onNextStep,
    onPrevStep,
    activeStep,
    isFirstStep,
    isLastStep,
  } = useStepForm({
    applicantId: applicantId || '',
    currentStep: getCurrentStep(location),
    steps: getAllStepsOrReopenedSteps(reopenedSteps || undefined),
    navigate,
  });

  const checkForRejections = useCallback(
    ({ application, onNoRejections }: CheckForRejectionsProps) => {
      if (
        application.applicant.status === ApplicantStatusEnum.ApplicantRejected
      ) {
        navigate(
          `/${APPLICATION_STATUS}/${application.applicant.rejection_reason_type}`,
        );
        return true;
      }
      onNoRejections();

      return false;
    },
    [navigate],
  );

  useEffect(() => {
    if (existingApplications && existingApplications.length > 0) {
      const identityVerificationBackgroundCheckFailed =
        validateIdentityVerificationBackgroundCheckFailed(existingApplications);

      if (identityVerificationBackgroundCheckFailed) navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingApplications]);

  useEffect(() => {
    if (!onboardingApplication) {
      if (!isFirstStep) navigate('/');
      else if (applicantId) {
        setLoading(true);
        getOnboardingApplication(applicantId)
          .then(currentOnboarding => {
            checkForRejections({
              application: currentOnboarding,
              onNoRejections: () => {
                setOnboardingApplication(currentOnboarding);
                setLoading(false);
              },
            });
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.error(error);
            setLoading(false);
          });
      }
    }
  }, [
    isFirstStep,
    navigate,
    onboardingApplication,
    applicantId,
    checkForRejections,
  ]);

  useEffect(() => {
    if (onboardingApplication) {
      const currentStep = getCurrentStep(location);

      // In case applicant has reopened steps, we need to redirect to the allowed step
      if (activeStep && activeStep !== currentStep)
        navigate(`/steps/${activeStep}/applicant/${applicantId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingApplication, applicantId, activeStep]);

  const title = useMemo(() => {
    return getPageTitle(t, activeStep);
  }, [t, activeStep]);

  return (
    <MainContentCard
      title={title}
      alert={
        onboardingApplication ? (
          <MultipleApplicationsAlert
            ongoingApplication={onboardingApplication}
          />
        ) : undefined
      }
      content={
        <div className="my-5">
          {loading && (
            <div className="h-screen flex justify-center">
              <Spinner />
            </div>
          )}
          {!loading && (
            <Outlet
              context={
                {
                  isNextEnable,
                  isPrevEnable,
                  onNextStep,
                  onPrevStep,
                  onboardingApplication,
                  setOnboardingApplication,
                  isFirstStep,
                  isLastStep,
                  checkForRejections,
                } satisfies OutletContextProps
              }
            />
          )}
        </div>
      }
    />
  );
}

export default ApplicationStepsPage;
